import React, { useState } from 'react';
import './GamePage.css';
import Island1 from '../components/Island1';
import Island2 from '../components/Island2';
import trailerVideo from '../videos/NewWildLifeHorizon.mp4';
import { FaGamepad, FaVrCardboard } from 'react-icons/fa';

function GamePage() {
  const [activeTab, setActiveTab] = useState('island1');
  const [isTrailerModalOpen, setIsTrailerModalOpen] = useState(false);

  // Direct download URL for the normal game from Google Drive
  const gameDownloadUrl = 'https://drive.google.com/uc?id=1n7I3W-Kvmnrqn875CO6cciXC_j1jl1nP&export=download';

  // Direct download URL for the VR game from Google Drive
  const vrGameDownloadUrl = 'https://drive.google.com/uc?id=11B6mDPj5vCBMm1C2-YlnKIYUbPuxxIyz&export=download';

  // Function to handle game download
  const handleDownload = () => {
    try {
      window.location.href = gameDownloadUrl;
    } catch (error) {
      console.error('Download failed:', error);
      alert('Failed to start download. Please try again.');
    }
  };

  // Function to handle VR game download
  const handleVRDownload = () => {
    try {
      window.location.href = vrGameDownloadUrl;
    } catch (error) {
      console.error('VR Download failed:', error);
      alert('Failed to start VR game download. Please try again.');
    }
  };

  return (
    <div className="gamepage-container">
      <h1>Wild Horizon Island Game</h1>
      
      {/* Trailer and download buttons */}
      <div className="trailer-and-download-container">
        <button className="trailer-button" onClick={() => setIsTrailerModalOpen(true)}>Watch Trailer</button>
        <button onClick={handleDownload} className="download-button"><FaGamepad /> Download Game</button>
        <button onClick={handleVRDownload} className="download-button vr"><FaVrCardboard /> Download VR Game</button>
      </div>
  
      {/* Tab buttons */}
      <div className="buttons-container">
        <button onClick={() => setActiveTab('island1')} className={`button ${activeTab === 'island1' ? 'active' : ''}`}>Island 1</button>
        <button onClick={() => setActiveTab('island2')} className={`button ${activeTab === 'island2' ? 'active' : ''}`}>Island 2</button>
      </div>
  
      {/* Displaying selected island */}
      {activeTab === 'island1' && <Island1 />}
      {activeTab === 'island2' && <Island2 />}
  
      {/* Trailer modal */}
      {isTrailerModalOpen && (
        <div className="modal" role="dialog" aria-modal="true" aria-labelledby="modalTitle" aria-describedby="modalDescription">
          <div className="modal-content">
            <button className="close-button" onClick={() => setIsTrailerModalOpen(false)} aria-label="Close trailer video">&times;</button>
            <h2 id="modalTitle">Trailer</h2>
            <video controls src={trailerVideo} className="trailer-video" id="modalDescription"></video>
          </div>
        </div>
      )}
    </div>
  );
}

export default GamePage;
